import React, {
  useEffect,
  useRef
} from 'react';
import {
  Box,
  Grid,
  Heading,
  Text,
  Link
} from 'theme-ui';
import { gsap } from 'gsap';

import HubspotForm from 'react-hubspot-form';

function SweepstakesForm() {
  const formRef = useRef(null);

  useEffect(() => {
    gsap.from([formRef.current], {
      autoAlpha: 0,
      y: '-50px',
      duration: 1,
      ease: 'power3.inOut'
    })
  })

  return (
    <Box
      sx={{
        pt: 4,
        position: 'relative',
        zIndex: 2
      }}>
      <Grid
        sx={{
          p: 2,
          justifyContent: 'center',
          textAlign: 'center'
        }}>
        <Heading
          sx={{
            color: 'white',
            lineHeight: 'heading',
            maxWidth: '30em',
            fontSize: [4, null, 5]
          }}>
          Thanks for engaging with VIZIO!
        </Heading>
        <Text
          color='white'
          sx={{
            maxWidth: '30em',
            lineHeight: 1.5,
            mx: 'auto'
          }}>
          Answer a few quick questions and be entered for a chance to win a VIZIO TV and Sound Bar.
        </Text>
        <Text
          color='white'
          sx={{
            maxWidth: '30em',
            lineHeight: 1.5,
            mx: 'auto'
          }}>
          Find out more information at&nbsp;
          <Link
            color="white"
            href="https://platformplus.vizio.com/userexperience"
            rel="noreferrer noopener"
            target="_blank">
            PlatformPlus.vizio.com
          </Link>
        </Text>
      </Grid>
      <Grid
        sx={{
          px: 2,
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            visibility: 'hidden',
            width: 400,
            maxWidth: '90%',
            margin: '1rem auto',
            p: 4,
            background: 'black',
            borderRadius: 5
          }}
          ref={formRef}>
          <HubspotForm
            portalId='8555612'
            formId='039cd2dd-c9fd-41d3-9bac-cbbd56ea2847'
            submitButtonClass='hubspot-submit'
            inlineMessage="Success! Thanks, we'll be in touch soon."
            translations={{
              en: {
                submitText: 'Submit'
              }
            }}
            cssClass='hubspot-form exit-form'
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default SweepstakesForm;

import React from 'react';
import {
  ThemeProvider,
  Box
} from 'theme-ui';
import theme from '../components/theme';
import GradientBar from '../components/GradientBar';
import Header from '../components/Header';
import SweepstakesForm from '../components/SweepstakesForm';
import SEO from '../components/seo';
import video from '../images/vizio.mp4';
import '../components/layout.scss';

function Survey() {
  return (
    <ThemeProvider
      sx={{
        background: 'black'
      }}
      theme={theme}>
      <SEO title='Survey' />
      <video className='background' src={video} playsInline autoPlay muted loop></video>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <GradientBar />
        <Header />
        <Box
          sx={{
            margin: 'auto 0'
          }}>
          <SweepstakesForm />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Survey;
